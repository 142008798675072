import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Formik } from 'formik';
import { pick, reduce } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import * as yup from 'yup';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import {
  getCandidateEmail,
  getCandidateFirstName,
  getCandidateGithubUrl,
  getCandidateLastName,
  getCandidateLinkedinUrl,
  getCandidateProvidedOriginalFirstName,
  getCandidateProvidedOriginalLastName,
  ICandidateContactInfo,
  useCandidateQuery,
  useUpdateCandidateContactInfoMutation,
  useUpdateCandidateCustomFieldsMutation,
} from '@/features/candidate';
import {
  FormikInputField,
  FormikSelectField,
  TId,
  useCountryList,
} from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { useWorkspaceId } from '@/hooks/router';

//region Main
export interface CandidateContactInfoEditModalProps {
  open: boolean;
  candidateId: TId;
  onSettled: () => void;
}

const CandidateContactInfoSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  contactEmail: yup.string().email('Invalid email'),
  phone: yup.string(),
  country: yup.string(),
  city: yup.string(),
  linkedin: yup
    .string()
    .matches(/^https:\/\/([a-z]{2,3}\.)?linkedin\.com/, 'Invalid URL format'),
  github: yup
    .string()
    .matches(/^https:\/\/([a-z]{2,3}\.)?github\.com/, 'Invalid URL format'),
});

export function CandidateContactInfoEditModal({
  open,
  candidateId,
  onSettled,
}: CandidateContactInfoEditModalProps) {
  const { countries } = useCountryList();
  const { data: candidate } = useCandidateQuery({ candidateId });
  const workspaceId = useWorkspaceId();
  const updateCandidateContactInfoMutation =
    useUpdateCandidateContactInfoMutation();
  const updateCandidateCustomFieldsMutation =
    useUpdateCandidateCustomFieldsMutation();
  const { addNotification } = useNotifications();
  const originalFirstName = getCandidateProvidedOriginalFirstName(candidate);
  const originalLastName = getCandidateProvidedOriginalLastName(candidate);
  const originalLinkedinUrl = getCandidateLinkedinUrl(candidate?.url || '');
  const originalGithubUrl = getCandidateGithubUrl(candidate?.url || '');
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();

  const save = async (allFields) => {
    const contactInfo = pick(allFields, Object.keys(candidate?.contactInfo!));
    const candidateFields = candidate?.candidateFields?.map((field) => ({
      ...field,
      value: allFields[field.name],
    }));

    if (!contactInfo.firstName && !contactInfo.lastName) {
      addNotification({
        type: 'candidate_contact_info_set_to_original',
        meta: { fieldName: 'Name', mandatory: true },
      });
      contactInfo.firstName = originalFirstName;
      contactInfo.lastName = originalLastName;
    } else {
      if (!contactInfo.firstName) {
        addNotification({
          type: 'candidate_contact_info_set_to_original',
          meta: { fieldName: 'First name', mandatory: true },
        });
        contactInfo.firstName = originalFirstName;
      }
      if (!contactInfo.lastName) {
        addNotification({
          type: 'candidate_contact_info_set_to_original',
          meta: { fieldName: 'Last name', mandatory: true },
        });
        contactInfo.lastName = originalLastName;
      }
    }

    if (!contactInfo.contactEmail) {
      addNotification({
        type: 'candidate_contact_info_set_to_original',
        meta: { fieldName: 'Email' },
      });
    }

    if (!!originalLinkedinUrl && !contactInfo.linkedin) {
      addNotification({
        type: 'candidate_contact_info_set_to_original',
        meta: { fieldName: 'LinkedIn URL' },
      });
    }

    if (!!originalGithubUrl && !contactInfo.github) {
      addNotification({
        type: 'candidate_contact_info_set_to_original',
        meta: { fieldName: 'GitHub URL' },
      });
    }

    await updateCandidateContactInfoMutation.mutateAsync({
      candidateId,
      contactInfo,
    });

    candidateFields?.length &&
      (await updateCandidateCustomFieldsMutation.mutateAsync({
        candidateId,
        candidateFields,
      }));

    addNotification({ type: 'saved' });

    onSettled();
  };

  if (!candidate) {
    return null;
  }

  return (
    <DSModal open={open}>
      <Header>
        Edit details
        <DSModal.CloseButton onClick={onSettled} fixed small />
      </Header>
      <DSModal.Separator />

      <Formik<ICandidateContactInfo>
        initialValues={{
          ...candidate.contactInfo,
          firstName: getCandidateFirstName(candidate),
          lastName: getCandidateLastName(candidate),
          contactEmail: getCandidateEmail(candidate),
          linkedin: candidate.contactInfo.linkedin || originalLinkedinUrl || '',
          github: candidate.contactInfo.github || originalGithubUrl || '',
          // Attach custom candidate fields
          ...reduce(
            candidate.candidateFields,
            (result, item) => {
              result[item.name] = item.value;
              return result;
            },
            {}
          ),
        }}
        onSubmit={save}
        validationSchema={CandidateContactInfoSchema}
      >
        {({ handleSubmit, isSubmitting, dirty }) => (
          <form onSubmit={handleSubmit}>
            <FieldGrid>
              <FormikInputField name="firstName" label="First name" />
              <FormikInputField name="lastName" label="Last name" />
              <FormikInputField name="contactEmail" label="Email" />
              <FormikInputField name="phone" label="Phone number" />
              <FormikInputField name="city" label="City" />
              <FormikSelectField
                label="Country"
                name="country"
                items={countries.map((country) => ({
                  id: country.name,
                  label: country.name,
                  labelText: country.name,
                }))}
              />
              <FormikInputField name="linkedin" label="LinkedIn" />
              <FormikInputField name="github" label="GitHub" />

              {/*Render custom candidate fields*/}
              {(candidate.candidateFields || []).map((el, index) => (
                <FormikInputField key={index} name={el.name} label={el.name} />
              ))}
            </FieldGrid>
            <DSModal.Footer float="right">
              <DSButton
                type="submit"
                disabled={!dirty}
                onClick={() => {
                  amplitude?.logEvent('editing candidate info', {
                    'workspace id': workspaceId,
                  });
                  posthog?.capture('hire_admin_app:editing candidate info', {
                    'workspace id': workspaceId,
                  });
                }}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </DSButton>
            </DSModal.Footer>
          </form>
        )}
      </Formik>
    </DSModal>
  );
}

//endregion

//region Styles
const Header = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const FieldGrid = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  display: grid;
  gap: 16px 16px;
  grid-template-columns: auto auto;
`;
//endregion

import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { usePostHog } from 'posthog-js/react';

import { checkClientError, MultiClientError } from '@/errors';
import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { useBulkReject } from '@/features/bulk-actions/queries/use-bulk-reject';
import {
  CANDIDATE_EMAILS_QUERY_KEY,
  CANDIDATE_HISTORY_QUERY_KEY,
  CANDIDATE_QUERY_KEY,
  useCandidateQuery,
  useRejectCandidateMutation,
  useSendBulkEmailMutation,
  useSendEmailMutation,
  useSendGmailEmailReplyMutation,
} from '@/features/candidate';
import {
  useEmailModalContext,
  useSendInvitationEmailMutation,
} from '@/features/email';
import { useNotifications } from '@/features/notifications';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline';
import { inviteCandidateToTest } from '@/features/test';
import { useApiError } from '@/hooks/api';
import { getCandidateEmail, getCandidateName } from '@/utils/candidate';

const useCandidateData = () => {
  const { emailModal, isOpen } = useEmailModalContext();

  const candidateQuery = useCandidateQuery(
    {
      candidateId:
        emailModal &&
        'meta' in emailModal &&
        'candidateId' in emailModal.meta &&
        emailModal.meta?.candidateId
          ? emailModal.meta.candidateId
          : '',
    },
    {
      enabled:
        (isOpen &&
          emailModal &&
          'meta' in emailModal &&
          'candidateId' in emailModal.meta) ||
        false,
    }
  );
  const candidate = candidateQuery.data ?? null;

  return {
    candidate,
  };
};

export const useHeader = () => {
  const { emailModal } = useEmailModalContext();
  const { candidate } = useCandidateData();

  const candidateName = candidate ? getCandidateName(candidate) : '';

  switch (emailModal?.type) {
    case 'invitation':
      return 'Invite test takers';
    case 'test-invitation':
      return `Invite candidate ${candidateName}`;
    case 'resend-test-invitation':
      return `Re-invite candidate ${candidateName}`;
    case 'next-stage-invitation':
    case 'resend-next-stage-invitation':
      return `Invite candidate ${candidateName} to next stage.`;
    case 'video-invitation':
    case 'resend-invitation':
      return `Invite candidate ${candidateName} to take video test.`;
    case 'rejection':
      return `Reject candidate`;
    case 'bulk-rejection':
      return 'Reject candidates in bulk';
    case 'contact-candidate':
      return 'Email candidate';
    case 'bulk-contact-candidate':
      return 'Email candidates in bulk';
    case 'gmail-bulk-contact-candidate':
      return 'Email candidates in bulk (Gmail)';
    case 'gmail-candidate':
      return emailModal.meta.emailId
        ? 'Reply to candidate (Gmail)'
        : 'Email candidate (Gmail)';
    default:
      return emailModal?.props?.header || 'Send email';
  }
};

export const useEmail = () => {
  const { emailModal } = useEmailModalContext();

  const { candidate } = useCandidateData();

  if (emailModal?.props?.email) {
    return emailModal?.props.email;
  }

  switch (emailModal?.type) {
    case 'test-invitation':
    case 'resend-test-invitation':
    case 'next-stage-invitation':
    case 'resend-next-stage-invitation':
    case 'video-invitation':
    case 'resend-invitation':
    case 'rejection':
    case 'contact-candidate':
    case 'gmail-candidate': {
      return getCandidateEmail(candidate);
    }
    default: {
      return emailModal?.props?.email;
    }
  }
};

export const useEmails = () => {
  const { emailModal } = useEmailModalContext();

  return emailModal?.props?.emails;
};

export const useOnSend = () => {
  const { emailModal } = useEmailModalContext();
  const candidateId =
    emailModal &&
    'meta' in emailModal &&
    'candidateId' in emailModal.meta &&
    emailModal.meta?.candidateId
      ? emailModal.meta.candidateId
      : '';
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const queryClient = useQueryClient();
  const sendInvitationEmailMutation = useSendInvitationEmailMutation();
  const rejectCandidateMutation = useRejectCandidateMutation();
  const bulkRejectCandidatesMutation = useBulkReject();
  const sendEmailMutation = useSendEmailMutation({ candidateId });
  const sendGmailEmailReplyMutation = useSendGmailEmailReplyMutation({
    candidateId,
  });
  const sendBulkContactCandidateMutation = useSendBulkEmailMutation();

  const { addNotification } = useNotifications();
  const handleApiError = useApiError();

  return useCallback(
    async (
      email: string | string[],
      subject: string,
      message: string,
      sendAt: Date | undefined,
      action: string,
      cc?: string[],
      bcc?: string[]
    ) => {
      try {
        switch (emailModal?.type) {
          case 'invitation': {
            try {
              await sendInvitationEmailMutation.mutateAsync(
                {
                  jobOpeningId: emailModal.meta.jobOpeningId,
                  invitationEmailParams: {
                    recipientEmailAddress: email,
                    subject,
                    message,
                    jobOpeningSlug: emailModal.meta.jobOpeningSlug,
                    sendAt,
                    testTakerCategoryId: emailModal.meta.categoryId,
                  },
                },
                {
                  onSuccess: (result) => {
                    if (
                      emailModal.meta.candidateId &&
                      !result?.failedRecipientEmailAddress?.length
                    ) {
                      queryClient.invalidateQueries([
                        ...CANDIDATE_QUERY_KEY,
                        emailModal.meta.candidateId,
                      ]);
                      queryClient.invalidateQueries([
                        ...CANDIDATE_HISTORY_QUERY_KEY,
                        emailModal.meta.candidateId,
                      ]);
                      queryClient.invalidateQueries([
                        ...CANDIDATE_EMAILS_QUERY_KEY,
                        emailModal.meta.candidateId,
                      ]);
                    }
                  },
                }
              );
            } catch {} //error is already handled in mutation

            break;
          }

          case 'test-invitation':
          case 'resend-test-invitation':
          case 'next-stage-invitation':
          case 'resend-next-stage-invitation': {
            await inviteCandidateToTest(
              emailModal.meta.testId,
              emailModal.meta.candidateId,
              {
                subject,
                message,
                resendInvitation:
                  emailModal.type === 'resend-next-stage-invitation' ||
                  emailModal.type === 'resend-test-invitation',
                sendAt,
              }
            );

            await queryClient.invalidateQueries([
              ...CANDIDATE_QUERY_KEY,
              emailModal.meta.candidateId,
            ]);
            await queryClient.invalidateQueries([
              ...CANDIDATE_HISTORY_QUERY_KEY,
              emailModal.meta.candidateId,
            ]);
            await queryClient.invalidateQueries([
              PIPELINE_CANDIDATES_KEY,
              emailModal.meta.candidateId,
            ]);
            await queryClient.invalidateQueries([
              ...CANDIDATE_EMAILS_QUERY_KEY,
              emailModal.meta.candidateId,
            ]);
            addNotification(
              sendAt
                ? { type: 'email_scheduled', meta: { date: sendAt } }
                : { type: 'email_sent' }
            );
            if (emailModal.meta.onSuccess) emailModal.meta.onSuccess();
            break;
          }

          case 'video-invitation':
          case 'resend-invitation': {
            await inviteCandidateToTest(
              emailModal.meta.testId,
              emailModal.meta.candidateId,
              {
                subject,
                message,
                resendInvitation: emailModal.type === 'resend-invitation',
                sendAt,
              }
            );

            await queryClient.invalidateQueries([
              ...CANDIDATE_QUERY_KEY,
              emailModal.meta.candidateId,
            ]);
            await queryClient.invalidateQueries([
              ...CANDIDATE_HISTORY_QUERY_KEY,
              emailModal.meta.candidateId,
            ]);
            await queryClient.invalidateQueries([
              ...CANDIDATE_EMAILS_QUERY_KEY,
              emailModal.meta.candidateId,
            ]);
            addNotification(
              sendAt
                ? { type: 'email_scheduled', meta: { date: sendAt } }
                : { type: 'email_sent' }
            );
            break;
          }

          case 'rejection': {
            await rejectCandidateMutation.mutateAsync({
              candidateId: emailModal.meta.candidateId,
              email:
                action === 'send'
                  ? {
                      subject,
                      message,
                      sendAt,
                    }
                  : null,
            });

            amplitude?.logEvent(
              `candidates/${
                action === 'reject-without-email'
                  ? 'skip and reject'
                  : 'send and reject'
              }`,
              {
                'candidate id': emailModal.meta.candidateId,
              }
            );
            posthog?.capture(
              `candidates/${
                action === 'reject-without-email'
                  ? 'skip and reject'
                  : 'send and reject'
              }`,
              {
                'candidate id': emailModal.meta.candidateId,
              }
            );

            action === 'send' &&
              addNotification(
                sendAt
                  ? { type: 'email_scheduled', meta: { date: sendAt } }
                  : { type: 'email_sent' }
              );

            addNotification({ type: 'candidate_rejected' });

            break;
          }

          case 'bulk-rejection': {
            if (action === 'send') {
              try {
                await bulkRejectCandidatesMutation.mutateAsync(
                  {
                    candidateIds: emailModal.meta.candidateIds,
                    subject,
                    message,
                    sendAt,
                  },
                  {
                    onSuccess: () => {
                      action === 'send' &&
                        addNotification(
                          sendAt
                            ? {
                                type: 'email_scheduled',
                                meta: { date: sendAt },
                              }
                            : { type: 'email_sent' }
                        );
                      if (emailModal.meta.onSuccess) {
                        emailModal.meta.onSuccess();
                      }
                    },
                  }
                );
              } catch {}
            } else {
              try {
                await bulkRejectCandidatesMutation.mutateAsync(
                  {
                    candidateIds: emailModal.meta.candidateIds,
                  },
                  {
                    onSuccess: () => {
                      if (emailModal.meta.onSuccess) {
                        emailModal.meta.onSuccess();
                      }
                    },
                  }
                );
              } catch {}
            }
            break;
          }

          case 'contact-candidate': {
            await sendEmailMutation.mutateAsync({
              candidateId: emailModal.meta.candidateId,
              subject,
              message,
              sendAt,
            });

            await queryClient.invalidateQueries([
              ...CANDIDATE_QUERY_KEY,
              emailModal.meta.candidateId,
            ]);
            await queryClient.invalidateQueries([
              ...CANDIDATE_HISTORY_QUERY_KEY,
              emailModal.meta.candidateId,
            ]);
            await queryClient.invalidateQueries([
              ...CANDIDATE_EMAILS_QUERY_KEY,
              emailModal.meta.candidateId,
            ]);

            addNotification({ type: 'email_sent' });
            break;
          }
          case 'gmail-bulk-contact-candidate':
          case 'bulk-contact-candidate': {
            try {
              await sendBulkContactCandidateMutation.mutateAsync(
                {
                  candidateIds: emailModal.meta.candidateIds,
                  subject,
                  message,
                  sendAt,
                },
                {
                  onSuccess: () => {
                    addNotification({ type: 'emails_sent' });

                    if (emailModal.meta.onSuccess) {
                      emailModal.meta.onSuccess();
                    }
                  },
                }
              );
            } catch {}

            break;
          }

          case 'gmail-candidate': {
            if (emailModal.meta.emailId) {
              // Send gmail reply
              await sendGmailEmailReplyMutation.mutateAsync({
                candidateId: emailModal.meta.candidateId,
                emailId: emailModal.meta.emailId,
                subject,
                message,
                cc,
                bcc,
                sendAt,
              });
            } else {
              // Send gmail
              await sendEmailMutation.mutateAsync({
                candidateId: emailModal.meta.candidateId,
                subject,
                message,
                cc,
                bcc,
                sendAt,
              });
            }
            addNotification({ type: 'email_sent' });
            break;
          }
        }
      } catch (err) {
        if (checkClientError(err, 'SubscriptionRequired')) {
          addNotification({ type: 'email_limit_exceeded' });
        } else if (checkClientError(err, 'TestInvitationResendLimitReached')) {
          addNotification({ type: 'test_invitation_resend_limit_reached' });
        } else if (checkClientError(err, 'BadTemplate')) {
          addNotification({
            type: 'email_bad_template',
            meta: { error: err.message },
          });
        } else if (err instanceof MultiClientError) {
          err.errors.forEach((error) =>
            addNotification({ type: 'email_sending_failed', meta: { error } })
          );
        } else {
          handleApiError(err);
        }

        return;
      }
    },
    [
      emailModal,
      sendInvitationEmailMutation,
      queryClient,
      addNotification,
      rejectCandidateMutation,
      amplitude,
      bulkRejectCandidatesMutation,
      sendBulkContactCandidateMutation,
      sendGmailEmailReplyMutation,
      sendEmailMutation,
      handleApiError,
      posthog,
    ]
  );
};

export const useEmailTemplatesType = () => {
  const { emailModal } = useEmailModalContext();

  switch (emailModal?.type) {
    case 'bulk-rejection':
      return 'rejection';
    case 'bulk-contact-candidate':
      return 'contact-candidate';
    case 'test-invitation':
    case 'resend-test-invitation':
      return 'invitation';
    case 'gmail-bulk-contact-candidate':
      return 'contact-candidate';

    default:
      return emailModal?.type;
  }
};

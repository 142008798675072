import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSField, DSInput, DSModal } from '@hundred5/design-system';
import { useFormikContext } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useMedia } from 'react-use';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { useCreateJobOpeningMutation } from '@/features/job-opening';
import { IAddTestToPipelineFormValues } from '@/features/test-library';

import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  ModalHeaderDescription,
} from './modal';

export const AddJobModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [jobOpeningName, setJobOpeningName] = useState('');
  const isMobile = useMedia('(max-width: 768px)');
  const createJobOpeningMutation = useCreateJobOpeningMutation();
  const form = useFormikContext<IAddTestToPipelineFormValues>();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const handleSave = async () => {
    const createdJobOpening = await createJobOpeningMutation.mutateAsync({
      name: jobOpeningName,
    });
    form.setFieldValue('jobOpeningId', createdJobOpening.id);
    amplitude?.logEvent('test library/new job created', {
      jobOpeningId: createdJobOpening.id,
    });
    posthog?.capture('hire_admin_app:test library/new job created', {
      jobOpeningId: createdJobOpening.id,
    });
    onClose();
  };
  return (
    <DSModal
      open={open}
      contentStyle={{ width: isMobile ? '100%' : '500px' }}
      layer={2}
    >
      <ModalHeader>Add new job</ModalHeader>
      <ModalHeaderDescription>
        Create a new job opening for this skills test
      </ModalHeaderDescription>
      <DSModal.CloseButton fixed small onClick={() => onClose()} />

      <DSModal.Separator />
      <Body>
        <DSField for="jobOpeningName" required label="job opening">
          <DSInput
            placeholder="Name your job"
            name="jobOpeningName"
            onChange={(event) => setJobOpeningName(event.target.value)}
          />
        </DSField>
      </Body>
      <ModalFooter>
        <ModalFooterButtons>
          <DSButton variant="secondary" onClick={onClose}>
            Cancel
          </DSButton>
          <DSButton
            disabled={!!!jobOpeningName || createJobOpeningMutation.isLoading}
            onClick={handleSave}
            variant="primary-purple"
          >
            Save
          </DSButton>
        </ModalFooterButtons>
      </ModalFooter>
    </DSModal>
  );
};

const Body = styled(ModalBody)`
  margin-bottom: 114px;
`;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { isFuture } from 'date-fns';
import { matchPath } from 'react-router';
import { useMedia } from 'react-use';
import store from 'store';

import { Tooltip, usePageLayout } from '@/features/common';
import { userHasConsentedToPreferences } from '@/features/gdpr/utils/permissions';
import { useMeQuery } from '@/features/me';
import { useHistory, useWorkspaceIdOrNull } from '@/hooks/router';

import { Checklist } from '../checklist';
import { JobList } from '../job-list';
import { LogoTrack } from '../logo-track';
import { MobileBanner } from '../mobile-banner';
import { NoWorkspace } from '../no-workspace';
import { Settings } from '../settings';
import { Shortcuts } from '../shortcuts';
import { WorkspaceSelector } from '../workspace-selector';

import { ExpandCollapse, Help, LogoHire } from './ui';

interface NavigationProps {
  children: React.ReactNode;
}

const SETTING_STORAGE_KEY = 'toggl_hire_settings_navigation_collapsed';

export function Navigation({ children }: NavigationProps) {
  const pageLayout = usePageLayout();
  const workspaceId = useWorkspaceIdOrNull();
  const history = useHistory();
  const isMobile = useMedia('(max-width: 768px)');
  const isSmallScreen = useMedia('(max-width: 1200px)');

  const meQuery = useMeQuery();

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [temporaryExpand, setTemporaryExpand] = useState(false);
  const [isChecklistClosedForSession, setIsChecklistClosedForSession] =
    useState(false);

  const isPausedSubscription = useMemo(() => {
    const currentWorkspace = meQuery.data?.workspaces?.find(
      (workspace) => workspace.id === workspaceId
    );
    const fromInFuture =
      !!currentWorkspace?.subscriptionPausedFrom &&
      isFuture(currentWorkspace.subscriptionPausedFrom);

    const tillInFuture =
      !!currentWorkspace?.subscriptionPausedUntil &&
      isFuture(currentWorkspace.subscriptionPausedUntil);

    return !fromInFuture && tillInFuture;
  }, [meQuery.data?.workspaces, workspaceId]);

  useEffect(() => {
    if (isSmallScreen) {
      // always collapse on small screens
      setCollapsed(true);
      return;
    }

    const storedCollapse = store.get(SETTING_STORAGE_KEY);
    const isCandidateView =
      matchPath(history.location.pathname, {
        path: '/admin/:workspaceId/openings/:jobOpeningId/pipeline/stage/:stageId/candidate/:candidateId',
      }) != null;

    if (storedCollapse != null) {
      // having stored setting overrides default behaviour
      setCollapsed(storedCollapse);
      return;
    }

    if (isCandidateView) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [history.location.pathname, isSmallScreen]);

  const handleMouseEnter = useCallback(() => {
    if (collapsed) {
      setTemporaryExpand(true);
    }
  }, [collapsed]);

  const handleMouseLeave = useCallback(() => {
    if (temporaryExpand) {
      setTemporaryExpand(false);
    }
  }, [temporaryExpand]);

  const handleCollapseToggle = () => {
    if (userHasConsentedToPreferences()) {
      store.set(SETTING_STORAGE_KEY, !collapsed);
    }
    setCollapsed((collapsed) => !collapsed);
  };

  if (!workspaceId) {
    return (
      <Container>
        <Content>{children}</Content>
        <Sidebar offsetTop={pageLayout.offsetTop}>
          <Tooltip delay={200} attribute="data-navigation-tooltip" />

          <StaticNavigation isSupportMode={meQuery.data?.id === '0'}>
            <LogoHire />
            <LogoTrack />
            <Help />
          </StaticNavigation>

          <CollapsibleNavigation collapsed={false} renderOnTop={false}>
            <NoWorkspace />
            <ScrollableContainer />
            <Settings />
          </CollapsibleNavigation>
        </Sidebar>
      </Container>
    );
  }

  return (
    <Container>
      <Content>{children}</Content>
      <Sidebar
        offsetTop={pageLayout.offsetTop}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        <Tooltip delay={200} attribute="data-navigation-tooltip" />

        <StaticNavigation isSupportMode={meQuery.data?.id === '0'}>
          <LogoHire />
          <LogoTrack />
          {!isMobile && (
            <ExpandCollapse
              collapsed={collapsed}
              onClick={() => !isSmallScreen && handleCollapseToggle()}
            />
          )}
          <Help />
        </StaticNavigation>

        <CollapsibleNavigation
          collapsed={isMobile ? false : collapsed}
          renderOnTop={isMobile ? false : collapsed && temporaryExpand}
        >
          <WorkspaceSelector />

          <ScrollableContainer disabled={isPausedSubscription}>
            <NavigationLists>
              <Shortcuts />
              <JobList />
            </NavigationLists>

            {isMobile || isChecklistClosedForSession ? null : (
              <Checklist onClose={() => setIsChecklistClosedForSession(true)} />
            )}

            <MobileBanner />
          </ScrollableContainer>

          <Settings />
        </CollapsibleNavigation>
      </Sidebar>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 769px) {
    flex-direction: row-reverse;
  }
`;

const Content = styled.main`
  flex: 1;

  @media (min-width: 769px) {
    min-width: 0;
  }
`;

const Sidebar = styled.aside<{ offsetTop: number }>`
  display: flex;
  flex-direction: row;
  height: ${({ offsetTop }) => `calc(100vh - ${offsetTop}px)`};
  overflow: visible;
  z-index: 100;

  @media (min-width: 769px) {
    position: sticky;
    top: ${({ offsetTop }) => offsetTop}px;
  }
`;

const StaticNavigation = styled.nav<{ isSupportMode: boolean }>`
  align-items: center;
  background: ${({ theme, isSupportMode }) =>
    isSupportMode ? theme.colors.red[100] : theme.colors.purple[120]};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 4px 0 8px;
  width: 48px;
  z-index: 20;
`;

const CollapsibleNavigation = styled.nav<{
  collapsed: boolean;
  renderOnTop: boolean;
}>`
  background: ${({ theme }) => theme.colors.purple[120]};
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 100%;
  position: ${({ collapsed }) => (collapsed ? 'absolute' : 'initial')};
  top: 0;
  transform: translateX(
    ${({ renderOnTop, collapsed }) =>
      renderOnTop ? '48px' : collapsed ? '-100%' : '0'}
  );
  transition: ${({ collapsed }) =>
    collapsed ? 'transform 200ms linear' : 'none'};
  width: 100%;
  z-index: 10;

  @media (min-width: 769px) {
    width: 200px;
  }
`;

const ScrollableContainer = styled.div<{ disabled?: boolean }>`
  padding: 16px 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.purple[100]};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const NavigationLists = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

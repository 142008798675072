import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSCheckbox,
  DSGrid,
  DSIconArrowDown,
  DSInlineField,
} from '@hundred5/design-system';
import { usePostHog } from 'posthog-js/react';
import { useMedia } from 'react-use';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { copyText, Icon, Loader, ScheduleMenu } from '@/features/common';
import {
  EmailDownloadModal,
  emailValidationSchema,
  FormikEmailBody,
  FormikEmailForm,
  FormikInputMultipleEmailsField,
  IEmailFormValuesBase,
  useSendInvitationEmailMutation,
} from '@/features/email';
import { showIntercom, trackIntercomEvent } from '@/features/intercom';
import {
  useAddCandidateMutation,
  useJobOpeningByIdQuery,
} from '@/features/job-opening';
import { getJobOpeningUrlForCandidate } from '@/features/job-opening/utils/job-opening-url';
import { useNotifications } from '@/features/notifications';
import { useWorkspaceStatsQuery } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

import SystemStageImagePng from '../assets/system-stage.png';
import { useShareModal } from '../hooks';
import {
  CandidateFields,
  ICandidateFieldsFormValues,
} from '../ui/candidate-fields';
import JobOpeningShareLink from '../ui/job-opening-share-link';

interface IShareFormValuesBase
  extends ICandidateFieldsFormValues,
    IEmailFormValuesBase {
  sendAt?: Date;
  emails: string[];
}

interface IShareFormValues extends IShareFormValuesBase, IEmailFormValuesBase {}

export const ShareModalEmailForm = () => {
  const { jobOpeningIdSelected, categorySelected, closeShareModal } =
    useShareModal();
  const sendInvitationEmailMutation = useSendInvitationEmailMutation();
  const { data: jobOpening } = useJobOpeningByIdQuery({
    jobOpeningId: jobOpeningIdSelected ?? '',
  });
  const testId =
    categorySelected?.testId ||
    jobOpening?.pipelineStages?.reduce((stagesWithTestIds, stage) => {
      if (stage.testId) {
        stagesWithTestIds.push(stage.testId);
      }
      return stagesWithTestIds;
    }, [] as string[])?.[0];
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const jobOpeningSlug = jobOpening?.slug;

  const [isOpenDetails, setIsOpenDetails] = useState<boolean>(true);
  const [isOpenSendEmail, setIsOpenSendEmail] = useState<boolean>(false);
  const workspaceId = useWorkspaceId();
  const { data: workspaceStats } = useWorkspaceStatsQuery({ workspaceId });
  const [sending, setSending] = useState<boolean>(false);
  const isMobile = useMedia('(max-width: 768px)');
  const disableInvitation =
    !jobOpeningIdSelected || !categorySelected || !categorySelected.test;

  useEffect(() => {
    setIsOpenDetails(!jobOpeningIdSelected);
    setIsOpenSendEmail(!!jobOpeningIdSelected && !!testId);
  }, [jobOpeningIdSelected, testId]);

  const addCandidateMutation = useAddCandidateMutation();
  const { addNotification } = useNotifications();

  const handleSubmit = async (values: IShareFormValues) => {
    if (categorySelected?.id && jobOpeningIdSelected) {
      setSending(true);
      if (values.emails.length === 1) {
        addCandidateMutation.mutate({
          jobOpeningId: jobOpeningIdSelected,
          attributes: {
            categoryId: categorySelected.id,
            email: values.emails[0],
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
          },
        });
      } else {
        for (const email of values.emails) {
          await addCandidateMutation.mutateAsync({
            jobOpeningId: jobOpeningIdSelected,
            attributes: {
              categoryId: categorySelected.id,
              email: email,
              firstName: '',
              lastName: '',
              phoneNumber: '',
            },
          });
        }
      }
      setSending(false);
      addNotification({ type: 'candidate_added' });
      closeShareModal();
    }
  };

  const handleSubmitWithEmail = async (values: IShareFormValues) => {
    if (
      values.emails &&
      values.emails.length > 0 &&
      values.emails.length <= 50 &&
      values.subject &&
      values.message
    ) {
      setSending(true);
      sendInvitationEmailMutation.mutate(
        {
          jobOpeningId: jobOpeningIdSelected,
          invitationEmailParams: {
            recipientEmailAddress: values.emails,
            subject: values.subject.replace(/\[(.*?)\]/g, (match, p1) => {
              const capitalized = p1.replace(/\s+(\w)/g, (_, cap) =>
                cap.toUpperCase()
              );
              return `[${capitalized.replace(/\s+/g, '')}]`;
            }),
            message: values.message,
            jobOpeningSlug,
            sendAt: values.sendAt,
            testTakerCategoryId: categorySelected?.id,
          },
        },
        {
          onSuccess: (result) => {
            if (!result?.failedRecipientEmailAddress?.length) {
              closeShareModal();
            }
          },
          onSettled: () => {
            setSending(false);

            amplitude?.logEvent('emails/send emails', {
              'workspace id': workspaceId,
              type: 'invitation',
              'email count': values.emails.length,
            });
            posthog?.capture('hire_admin_app:emails/send emails', {
              'workspace id': workspaceId,
              type: 'invitation',
              'email count': values.emails.length,
            });

            trackIntercomEvent('invited_candidates');
          },
        }
      );
    }
  };

  const canUnlockCandidate =
    workspaceStats && workspaceStats.unlockableTestTakersRemaining > 0;

  const submitDisabled =
    !jobOpeningIdSelected || !categorySelected || !canUnlockCandidate;

  useEffect(() => {
    if (disableInvitation) {
      setIsOpenSendEmail(false);
    } else {
      setIsOpenSendEmail(true);
    }
  }, [disableInvitation]);

  const copyTestLink = () => {
    if (jobOpeningSlug) {
      copyText(
        getJobOpeningUrlForCandidate({
          jobOpeningSlug: jobOpeningSlug!,
          isJobDescription: true,
        })
      );
      closeShareModal();
      addNotification({ type: 'copied_to_clipboard' });
    }
  };

  return (
    <>
      <EmailFormWrapper>
        {jobOpeningIdSelected && !categorySelected ? (
          <Loader />
        ) : (
          <FormikEmailForm<IShareFormValuesBase>
            onSubmit={isOpenSendEmail ? handleSubmitWithEmail : handleSubmit}
            initialValues={{
              message: '',
              subject: '',
              emails: [],
              firstName: '',
              lastName: '',
              phoneNumber: '',
            }}
            validationSchema={
              isOpenSendEmail ? emailValidationSchema : undefined
            }
          >
            {({ values, setFieldValue, submitForm, errors, isValid }) => (
              <>
                {categorySelected?.isSystem ? (
                  <SystemStageContainer>
                    <img src={SystemStageImagePng} />
                    <SystemStageText>
                      To share your job opening with candidates, copy the link
                      to the job page and add it wherever you post your
                      openings. For example you can share on your website, a
                      jobs board, or LinkedIn.
                    </SystemStageText>
                  </SystemStageContainer>
                ) : (
                  <>
                    <DSGrid gap="8px">
                      <FormikInputMultipleEmailsField
                        name="emails"
                        label="Email"
                        required
                        disableFileInput={isMobile}
                      />
                      {!isMobile && !isOpenSendEmail && (
                        <>
                          <AddMoreDetails
                            onClick={() => {
                              setIsOpenDetails(!isOpenDetails);
                            }}
                            disabled={values.emails.length > 1}
                            {...(values.emails.length > 1 && {
                              'data-rh':
                                'The option to upload candidate data in bulk is coming soon!',
                              'data-rh-at': 'top',
                            })}
                          >
                            Add more details{' '}
                            <ArrowWrapper isOpenDetails={isOpenDetails}>
                              <DSIconArrowDown size="8px" />
                            </ArrowWrapper>
                          </AddMoreDetails>

                          {isOpenDetails && values.emails.length < 2 && (
                            <CandidateFields />
                          )}
                        </>
                      )}
                    </DSGrid>

                    <SendInviteWrapper>
                      <DSInlineField for="send-invite">
                        <DSCheckbox
                          id="send-invite"
                          checked={isOpenSendEmail}
                          disabled={disableInvitation}
                          onClick={() => {
                            if (!disableInvitation) {
                              setIsOpenSendEmail(!isOpenSendEmail);
                            }
                          }}
                        />
                        <SentInviteText
                          disabled={disableInvitation}
                          {...(disableInvitation && {
                            'data-rh':
                              'There’s no test added to the selected pipeline stage.',
                            'data-rh-at': 'top',
                          })}
                        >
                          Send invite
                        </SentInviteText>
                      </DSInlineField>
                    </SendInviteWrapper>
                    {isOpenSendEmail && (
                      <Container>
                        <FormikEmailBody
                          emailType={
                            categorySelected?.interviewId
                              ? 'interview-invitation'
                              : 'invitation'
                          }
                          interviewId={categorySelected?.interviewId ?? null}
                          testId={categorySelected?.test?.id}
                          jobOpeningId={jobOpeningIdSelected}
                        />
                      </Container>
                    )}
                  </>
                )}

                <FooterButtonWrapper>
                  {categorySelected?.isSystem ? (
                    <>
                      <ImportCandidatesText>
                        Want to import candidates from a previous ATS?{' '}
                        <ImportCandidatesLetUsKnowText onClick={showIntercom}>
                          Let us know.
                        </ImportCandidatesLetUsKnowText>
                      </ImportCandidatesText>
                      <DSButton
                        Icon={<Icon icon={regular('link-simple')} />}
                        onClick={copyTestLink}
                      >
                        Copy job link
                      </DSButton>
                    </>
                  ) : (
                    <>
                      {!isMobile && (
                        <JobOpeningShareLink>
                          Copy test link
                        </JobOpeningShareLink>
                      )}
                      <AddCandidateWrapper>
                        <AddCandidateButton
                          variant="primary"
                          type="submit"
                          disabled={
                            submitDisabled ||
                            !isValid ||
                            sending ||
                            values.emails.length === 0
                          }
                          {...(errors.emails
                            ? {
                                'data-rh':
                                  'At least one e-mail address is required.',
                                'data-rh-at': 'top',
                              }
                            : errors.subject || errors.message
                              ? {
                                  'data-rh':
                                    'An e-mail must have subject and body.',
                                  'data-rh-at': 'top',
                                }
                              : !canUnlockCandidate && {
                                  'data-rh':
                                    'You have reached the candidate limit. Upgrade to add more candidates.',
                                  'data-rh-at': 'top',
                                })}
                        >
                          Add Candidate
                        </AddCandidateButton>
                        {!isMobile && (
                          <ScheduleMenu
                            disabled={
                              submitDisabled ||
                              !isValid ||
                              sending ||
                              !isOpenSendEmail ||
                              values.emails.length === 0
                            }
                            onScheduleSend={(time) => {
                              setFieldValue('sendAt', time);
                              submitForm();
                            }}
                          />
                        )}
                      </AddCandidateWrapper>
                    </>
                  )}
                </FooterButtonWrapper>
              </>
            )}
          </FormikEmailForm>
        )}
      </EmailFormWrapper>
      <EmailDownloadModal />
    </>
  );
};

const EmailFormWrapper = styled.div`
  padding: 16px 36px;
  @media all and (max-width: 768px) {
    padding: 16px;
  }
`;

const AddMoreDetails = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${(props) => props.theme.colors.purple[100]};
  display: flex;
  width: 150px;
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};

  @media all and (max-width: 768px) {
    display: none;
  }
`;

const SendInviteWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: space-between;
`;

const SentInviteText = styled.div<{ disabled: boolean }>`
  margin-left: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  color: ${(props) => props.theme.colors.purple[100]};
`;

const ArrowWrapper = styled.div<{ isOpenDetails: boolean }>`
  transform: ${(props) => (props.isOpenDetails ? `rotate(180deg)` : '')};
  transform-origin: center;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Container = styled.div`
  margin-top: 12px;
`;

const FooterButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 24px;
  position: sticky;
  bottom: -40px;
  background: ${(props) => props.theme.colors.white};
  margin-left: -10px;
  margin-right: -10px;
  padding: 12px 10px 36px 10px;

  @media all and (max-width: 768px) {
    position: relative;
    bottom: 0;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    flex-direction: column;
  }
`;
const AddCandidateWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;

  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const AddCandidateButton = styled(DSButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;

  @media all and (max-width: 768px) {
    width: 100%;
    border-radius: 8px;
  }
`;

const SystemStageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.peach[50]};
  padding-left: 12px;
  padding-top: 12px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
`;

const SystemStageText = styled.span`
  color: ${(props) => props.theme.colors.purple[70]};
  margin-left: 24px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
`;

const ImportCandidatesText = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
`;

const ImportCandidatesLetUsKnowText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

import { useCallback, useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { useCreateJobOpeningMutation } from '@/features/job-opening';
import { useMeQuery } from '@/features/me';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import { useSubmitOnboardingWizardMutation } from '../queries';

export function useOnboardingWelcome() {
  const workspaceId = useWorkspaceId();
  const history = useHistory();
  const isMobile = useMedia('(max-width: 768px)');
  const { mutateAsync: submitOnboardingMutation } =
    useSubmitOnboardingWizardMutation();
  const { mutateAsync: createJobOpeningMutation } =
    useCreateJobOpeningMutation();
  const meQuery = useMeQuery();
  const [onboardingDone, setOnboardingDone] = useState(false);

  const onBoardingWizardCompleted = meQuery.data?.workspaces?.find(
    (workspace) => workspace.id === workspaceId
  )?.onboardingDone;

  const handleOnboarding = useCallback(async () => {
    const createdJobOpening = await createJobOpeningMutation({
      name: 'New job',
    });
    await submitOnboardingMutation({
      workspaceId,
    });
    if (isMobile) {
      history.replace(`/admin/${workspaceId}/openings`);
    } else {
      history.replace({
        pathname: `/admin/${workspaceId}/openings/${createdJobOpening.id}/pipeline/onboarding-welcome`,
        search: history.location.search,
      });
    }
    setOnboardingDone(true);
  }, [
    createJobOpeningMutation,
    submitOnboardingMutation,
    workspaceId,
    history,
    isMobile,
  ]);

  useEffect(() => {
    if (onBoardingWizardCompleted === false) {
      handleOnboarding();
    }
  }, [handleOnboarding, onBoardingWizardCompleted, workspaceId, history]);
  return onboardingDone || onBoardingWizardCompleted;
}

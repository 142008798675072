import api from '@/api/api';
import { TId } from '@/features/common';
import { ITest, TTestType } from '@/features/test';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function fetchTest(params: { testId: TId }): Promise<ITest> {
  const response = await api.get(`/tests/${params.testId}`);
  let test = parseAPIData<ITest>(response.data);

  // TODO: remove this when BE gets rid of smart tests
  // We need to convert smart tests into custom otherwise we won't get all the questions
  if (test.isSmart) {
    test = await convertIntoCustomTest({ testId: test.id });
  }

  return test;
}

export async function updateTest(params: {
  testId: TId;
  test: Partial<ITest>;
}): Promise<ITest> {
  const response = await api.patch(
    `/tests/${params.testId}`,
    serialiseAPIData(params.test)
  );

  return parseAPIData(response.data);
}

export async function createTest(params: {
  jobOpeningId: TId;
  attributes: {
    name: string;
    type: TTestType;
    durationInSeconds: number;
    isQuestionRatingDisabled: boolean;
    percentToPass: number;
    pipelineStageId: TId;
    skills: { id: string; difficulty: string }[];
  };
}): Promise<ITest> {
  const test = {
    ...params.attributes,
  };
  const response = await api.post(
    `/openings/${params.jobOpeningId}/test`,
    serialiseAPIData(test)
  );

  return parseAPIData<ITest>(response.data);
}

export async function deleteTest(testId: TId): Promise<void> {
  await api.delete(`/tests/${testId}`);
}

export async function getTryTestToken(params: {
  testId: TId;
}): Promise<{ token: string }> {
  const response = await api.post(`/tests/${params.testId}/try`);

  return parseAPIData(response.data);
}

export async function convertIntoCustomTest(params: {
  testId: TId;
}): Promise<ITest> {
  const response = await api.post(`/tests/${params.testId}/convert`);
  return parseAPIData(response.data);
}

export async function inviteCandidateToTest(
  testId: string,
  candidateId: string,
  email: {
    message: string;
    subject: string;
    resendInvitation: boolean;
    candidateTags?: TId[];
    sendAt?: Date;
  }
): Promise<void> {
  return await api.post(
    `/tests/${testId}/invite/${candidateId}`,
    {
      email: {
        subject: email.subject,
        message: email.message,
        resend: email.resendInvitation,
        test_taker_tag_ids: email.candidateTags?.map((id) => Number(id)),
        send_at: email.sendAt?.toISOString(),
      },
    },
    {
      captchaAction: 'test_invite',
    }
  );
}

import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { Icon, Tooltip } from '@/features/common';
import {
  getSharedAuthEnabled,
  useTogglAccountsMeQuery,
} from '@/features/toggl-accounts';
import { useWorkspaceIdOrNull } from '@/hooks/router';
import { getDomainZone, isDemoEnv, isSupportEnv } from '@/utils/env';

import { TooltipContent, TooltipContentGrid, Illustration, Logo } from './ui';

export function LogoTrack() {
  const workspaceId = useWorkspaceIdOrNull();
  const sharedAuthEnabled = getSharedAuthEnabled();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();

  const { data } = useTogglAccountsMeQuery({
    enabled: sharedAuthEnabled && !isDemoEnv() && !isSupportEnv(),
  });

  const hasTrackUser = data?.trackUser;
  const href = hasTrackUser
    ? `https://track.toggl.${getDomainZone()}`
    : 'https://toggl.com';

  const logEvent = () => {
    const event = hasTrackUser ? 'go to' : 'learn about';
    amplitude?.logEvent(`track/${event}`, {
      'workspace id': workspaceId,
      'user id': data?.id,
    });
    posthog?.capture(`hire_admin_app:track/${event}`, {
      'workspace id': workspaceId,
      'user id': data?.id,
    });
  };

  return (
    <>
      <Tooltip
        delay={200}
        attribute="data-track-tooltip"
        persist
        onRenderContent={() =>
          hasTrackUser ? (
            <TooltipContent>Go to Toggl Track</TooltipContent>
          ) : (
            <TooltipContentGrid>
              <article>
                <h2>Try Toggl Track for free</h2>
                <p>
                  Maximize your team’s productivity with seamless time tracking
                  and customized reporting
                </p>
              </article>
              <aside>
                <Illustration />
              </aside>
              <footer>
                <DSButton
                  variant="secondary"
                  onClick={() => {
                    logEvent();
                    window.open(href, '_blank');
                  }}
                >
                  Learn about Toggl Track
                  <Icon icon={solid('arrow-up-right-from-square')} />
                </DSButton>
              </footer>
            </TooltipContentGrid>
          )
        }
      />
      <LogoContainer
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        data-track-tooltip
        data-track-tooltip-at="right"
        onClick={() => {
          logEvent();
        }}
      >
        <Logo />
      </LogoContainer>
    </>
  );
}

const LogoContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  cursor: pointer;

  svg path {
    transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:not(:hover):not(:focus) {
    svg path {
      fill: ${({ theme }) => theme.colors.purple[60]};
    }
  }
`;

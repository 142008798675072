export function isDemoEnv() {
  return window.location.hostname.startsWith('demo.');
}

export function isSupportEnv() {
  return window.location.hostname.startsWith('support.');
}

export function getDomainZone() {
  return window.location.hostname.endsWith('.com') ? 'com' : 'space';
}

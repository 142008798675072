import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { useMedia } from 'react-use';

import { Icon } from '@/features/common';
import { usePreferenceMutation, usePreferencesQuery } from '@/features/me';
import { useWorkspace } from '@/features/workspace';

import { TestType, TTestType } from '../../../test/types';

import { ONBOARDING_TEST_MODAL_COPIES } from './onboarding-test-modal.utils';

interface IOnboardingTestModalProps {
  testType: TTestType;
}

export function OnboardingTestModal(props: IOnboardingTestModalProps) {
  const workspace = useWorkspace();
  const isMobile = useMedia('(max-width: 768px)');
  const { testType } = props;
  const preferencesQuery = usePreferencesQuery();
  const preferenceMutation = usePreferenceMutation();
  const testOnboardingOpenedPrefernce =
    preferencesQuery.data?.testOnboardingOpened;
  const openedBefore = !!testOnboardingOpenedPrefernce?.[testType];
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] =
    useState<boolean>(!openedBefore);

  if (openedBefore) {
    return null;
  }

  if (!workspace) {
    return null;
  }

  const onClose = () => {
    preferenceMutation.mutate({
      name: 'testOnboardingOpened',
      value: {
        [TestType.Quiz]:
          testType === TestType.Quiz ||
          !!testOnboardingOpenedPrefernce?.[TestType.Quiz],
        [TestType.Video]:
          testType === TestType.Video ||
          !!testOnboardingOpenedPrefernce?.[TestType.Video],
        [TestType.Homework]:
          testType === TestType.Homework ||
          !!testOnboardingOpenedPrefernce?.[TestType.Homework],
      },
    });
    setIsOnboardingModalOpen(false);
  };

  return (
    <DSModal
      open={isOnboardingModalOpen}
      onClose={onClose}
      contentStyle={{
        width: isMobile ? '100%' : '800px',
      }}
    >
      <CloseButtonContainer>
        <DSModal.CloseButton small onClick={onClose} />
      </CloseButtonContainer>
      <Container>
        <ContentContainer>
          <div>
            <ModalHeader>
              {ONBOARDING_TEST_MODAL_COPIES[testType].title}
            </ModalHeader>
            <ModalDescription>
              {ONBOARDING_TEST_MODAL_COPIES[testType].description}
            </ModalDescription>
            <ModalListContainer>
              {ONBOARDING_TEST_MODAL_COPIES[testType].list.map((item) => (
                <ListItem key={item.title}>
                  <IconTitleContainer>
                    <Icon icon={item.icon} width={16} />
                    <Title>{item.title}</Title>
                  </IconTitleContainer>
                  <Description>{item.description}</Description>
                </ListItem>
              ))}
            </ModalListContainer>
          </div>
          <DSButton
            variant="primary-purple"
            fullWidth
            onClick={() => {
              onClose();
            }}
          >
            Get started
          </DSButton>
        </ContentContainer>
        <ImageContainer>
          <Image src={ONBOARDING_TEST_MODAL_COPIES[testType].illustration} />
        </ImageContainer>
      </Container>
    </DSModal>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 36px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-left: 28px;
`;

const IconTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 12px;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`;

const ModalHeader = styled.div`
  color: ${({ theme }) => theme.colors.purple[100]};
  font-family: GT Haptik;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  margin-bottom: 8px;
`;

const ModalDescription = styled.div`
  color: ${({ theme }) => theme.colors.purple[60]};
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  max-width: 431px;
  margin-bottom: 16px;
`;

const ModalListContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: ${({ theme }) => theme.colors.purple[100]};
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 44px;
`;

const ListItem = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  min-width: 360px;
  height: 482px;
  background-color: ${({ theme }) => theme.colors.purple[10]};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
const Image = styled.img`
  max-width: 288px;
`;

import React from 'react';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';

export interface IEmailFormValuesBase extends FormikValues {
  subject: string;
  message: string;
}
export const FormikEmailForm = <T extends IEmailFormValuesBase>({
  children,
  initialValues,
  onSubmit,
  validationSchema,
}: {
  children: (formikProps: FormikProps<T>) => React.ReactNode;
  initialValues: T;
  onSubmit: (
    values: T,
    formikHelpers?: FormikHelpers<T>
  ) => void | Promise<any>;
  validationSchema?: any;
}) => {
  return (
    <Formik<T>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount={false}
      isInitialValid={false}
      enableReinitialize
    >
      {(formikProps) => <Form>{children(formikProps)}</Form>}
    </Formik>
  );
};

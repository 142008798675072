import React from 'react';
import { useMedia } from 'react-use';

import { useBookmarkedJobOpenings } from '@/features/dashboard';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import { NavigationList } from '../navigation-list';

export function JobList() {
  const history = useHistory();
  const workspaceId = useWorkspaceId();

  const isMobile = useMedia('(max-width: 768px)');
  const { jobOpenings } = useBookmarkedJobOpenings({ filter: 'open' });
  const openingsToDisplay = jobOpenings.slice(0, 10);

  return (
    <NavigationList>
      {openingsToDisplay.length > 0 &&
        openingsToDisplay.map((opening) => {
          const selected = history.location.pathname.includes(
            `/openings/${opening.id}`
          );

          return (
            <NavigationList.Item
              key={opening.id}
              label={opening.name}
              url={`/admin/${workspaceId}/openings${
                !isMobile ? `/${opening.id}/pipeline` : `?desktopOnly=true`
              }`}
              active={selected}
              badge={{
                number: opening.totalCandidates,
              }}
            />
          );
        })}
    </NavigationList>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DSSelect } from '@hundred5/design-system';
import { flatten } from 'lodash';

import { usePipelineCategoriesQuery } from '@/features/pipeline';
import { useTestsQuery } from '@/features/test';

import { useShareModal } from '../hooks';

//region Main
const TestSelector = () => {
  const {
    jobOpeningIdSelected,
    categorySelected,
    selectCategory,
    isShareModalOpen,
  } = useShareModal();

  const [initialized, setInitialized] = useState<boolean>(false);
  const pipelineCategoriesQuery = usePipelineCategoriesQuery(
    { jobOpeningId: jobOpeningIdSelected ?? '' },
    { enabled: !!jobOpeningIdSelected }
  );

  const initializing = pipelineCategoriesQuery.isLoading;
  const pipelineCategories = useMemo(() => {
    return pipelineCategoriesQuery.data || [];
  }, [pipelineCategoriesQuery.data]);
  const testIds: string[] = [];

  pipelineCategories?.forEach((category) => {
    if (category.testId) {
      testIds.push(category.testId);
    }
  });

  const testsQuery = useTestsQuery({ testIds }, { enabled: isShareModalOpen });
  const tests = useMemo(
    () => flatten(testsQuery.map((testQuery) => testQuery.data || [])),
    [testsQuery]
  );

  const findTestById = useCallback(
    (testId: string) => {
      return tests?.find((test) => test.id === testId);
    },
    [tests]
  );

  useEffect(() => {
    if (
      pipelineCategories.length > 0 &&
      !categorySelected?.test &&
      !initialized &&
      !testsQuery.find((item) => item.isLoading)
    ) {
      selectCategory({
        ...pipelineCategories[0],
        test: findTestById(pipelineCategories[0]?.testId!),
      });

      setInitialized(true);
    }
  }, [
    selectCategory,
    findTestById,
    pipelineCategories,
    categorySelected,
    initialized,
    testsQuery,
  ]);

  return (
    <DSSelect
      items={pipelineCategories.map((candidateCategory) => ({
        id: candidateCategory.id,
        label: candidateCategory.name,
        labelText: candidateCategory.name,
      }))}
      disabled={!jobOpeningIdSelected || !pipelineCategories || initializing}
      placeholder="Select stage"
      selectedItemId={categorySelected?.id}
      onChange={(selected) => {
        if (selected) {
          const category = pipelineCategories.find(
            (category) => category.id === selected.id
          );
          selectCategory({
            ...category!,
            test: findTestById(category?.testId!),
          });
        }
      }}
    />
  );
};
//endregion

export default TestSelector;

import { useEffect, useMemo } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { userHasConsentedToMarketing } from '@/features/gdpr/utils/permissions';
import { useMeQuery } from '@/features/me';
import { useWorkspaceUsersQuery } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';
import { isDemoEnv } from '@/utils/env';

export function useAmplitudeForWorkspace() {
  const workspaceId = useWorkspaceId();
  const meQuery = useMeQuery();
  const workspaceUsersQuery = useWorkspaceUsersQuery();

  const workspaceUser = useMemo(() => {
    if (!meQuery.isSuccess || !workspaceUsersQuery.isSuccess) {
      return null;
    }

    return workspaceUsersQuery.data.find((user) => user.id === meQuery.data.id);
  }, [
    meQuery.isSuccess,
    meQuery.data?.id,
    workspaceUsersQuery.isSuccess,
    workspaceUsersQuery.data,
  ]);

  const workspace = useMemo(() => {
    if (!meQuery.isSuccess) {
      return null;
    }

    return meQuery.data.workspaces?.find(
      (workspace) => workspace.id === workspaceId
    );
  }, [meQuery.isSuccess, meQuery.data, workspaceId]);

  useEffect(() => {
    if (workspaceUser != null && userHasConsentedToMarketing()) {
      const identifyEvent = new amplitude.Identify();

      identifyEvent.set('support', workspaceUser.id === '0');
      identifyEvent.set('demo', isDemoEnv());

      amplitude.setUserId(workspaceUser.id);
      identifyEvent.set('user_role', workspaceUser.role);
      amplitude.identify(identifyEvent);
    }
  }, [workspaceUser]);

  useEffect(() => {
    if (workspace != null && userHasConsentedToMarketing()) {
      const identifyEvent = new amplitude.Identify();

      identifyEvent.set('workspace_id', workspace.id);
      identifyEvent.set('subscription_plan', workspace.subscriptionPlan);

      amplitude.identify(identifyEvent);
    }
  }, [workspace]);
}

import React from 'react';
import * as Sentry from '@sentry/react';
import { first } from 'lodash';
import { Redirect, Route as ReactRouterRoute, Switch } from 'react-router-dom';

import { useAuthentication } from '@/features/authentication/providers/authentication.context';
import { useMeQuery, usePreferencesQuery } from '@/features/me';
import { getSharedAuthEnabled } from '@/features/toggl-accounts';
import { WorkspaceApp } from '@/features/workspace';
import { useLocation } from '@/hooks/router';
import ProfileSettingsPage from '@/pages/profile-settings-page';
import { getMatchParam } from '@/utils/router';

import { AdminPage } from '../admin-page';
import UpdateSelectedWorkspace from '../update-selected-workspace/update-selected-workspace';

import * as pages from './pages';

const Route = Sentry.withSentryRouting(ReactRouterRoute);

export const AdminApp = () => {
  const location = useLocation();
  const { authenticated } = useAuthentication();
  const sharedAuthEnabled = getSharedAuthEnabled();

  const meQuery = useMeQuery({ enabled: !!authenticated || sharedAuthEnabled });
  const preferencesQuery = usePreferencesQuery({
    enabled: !!authenticated || (sharedAuthEnabled && meQuery.isSuccess),
  });

  if (sharedAuthEnabled) {
    if (!meQuery.isSuccess) return null;
  } else {
    if (authenticated === null) {
      return null;
    }

    if (!authenticated) {
      return <pages.LogInPage />;
    }
  }

  if (
    !meQuery.data?.isEmailVerified &&
    location.pathname !== '/admin/email-verification'
  ) {
    return <pages.EmailVerificationMessagePage />;
  }

  if (!meQuery.isSuccess || !preferencesQuery.isSuccess) {
    return null;
  }

  const selectedWorkspace = preferencesQuery.data.selectedWorkspace;
  const validSelectedWorkspace = meQuery.data.workspaces?.some(
    (ws) => ws.id === selectedWorkspace
  )
    ? selectedWorkspace
    : null;

  const defaultWorkspaceId: string | null =
    validSelectedWorkspace ?? first(meQuery.data.workspaces)?.id ?? null;

  return (
    <Switch>
      <Route
        // {/*
        // @ts-ignore FIXME `from` prop after sentry and router updates
        // */}
        from="/admin/settings/profile/gmail"
        render={() => {
          //  After completing the Gmail integration authentication process,
          // we return to the following URL: /admin/settings/profile/gmail${queryParams}.
          // However, as the workspace ID is not included in the URL, we must redirect to the workspace settings profile page and append the queryParams for clarity and proper navigation
          if (defaultWorkspaceId != null && location.search) {
            return (
              <Redirect
                to={`/admin/${defaultWorkspaceId}/settings/profile/${location.search}`}
              />
            );
          } else {
            return <Redirect to="/admin/profile" />;
          }
        }}
      />
      <Route
        path="/admin/email-verification"
        component={pages.EmailVerificationPage}
      />

      <Route
        path="/admin/profile"
        render={() => {
          if (defaultWorkspaceId != null) {
            return (
              <Redirect to={`/admin/${defaultWorkspaceId}/settings/profile`} />
            );
          } else {
            return (
              <AdminPage>
                <ProfileSettingsPage />
              </AdminPage>
            );
          }
        }}
      />

      {sharedAuthEnabled && (
        <Route
          path={`/admin/account`}
          render={() => (
            <AdminPage>
              <pages.AccountSettingsPage />
            </AdminPage>
          )}
        />
      )}

      <Route path="/admin/new" component={pages.CreateWorkspacePage} />

      <Route
        path="/admin/billing"
        render={() => {
          if (defaultWorkspaceId != null) {
            return (
              <Redirect to={`/admin/${defaultWorkspaceId}/settings/billing`} />
            );
          } else {
            return <Redirect to="/admin/profile" />;
          }
        }}
      />

      <Route
        path="/admin/integrations"
        render={() => {
          if (defaultWorkspaceId != null) {
            return (
              <Redirect
                to={`/admin/${defaultWorkspaceId}/settings/integrations`}
              />
            );
          } else {
            return <Redirect to="/admin/profile" />;
          }
        }}
      />

      <Route
        path="/admin/:workspace"
        render={(props) => {
          const workspaceId = getMatchParam(props.match, 'workspace');

          return (
            <>
              <UpdateSelectedWorkspace currentWorkspaceId={workspaceId} />
              <WorkspaceApp {...props} key={workspaceId} />
            </>
          );
        }}
      />

      <Route
        path="/admin"
        render={() => {
          if (defaultWorkspaceId != null) {
            return (
              <Redirect
                to={{
                  pathname: `/admin/${defaultWorkspaceId}`,
                  search: location.search,
                }}
              />
            );
          } else {
            return <Redirect to="/admin/profile" />;
          }
        }}
      />
    </Switch>
  );
};

import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSContextMenu,
  DSContextMenuButton,
  DSField,
  DSInput,
  DSSelect,
} from '@hundred5/design-system';
import { usePostHog } from 'posthog-js/react';
import { useRouteMatch } from 'react-router-dom';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { Icon, Markdown } from '@/features/common';
import {
  createEditorState,
  getEditorMarkdown,
} from '@/features/common/components/custom-text-editor';
import {
  useDeleteQuestion,
  useUpdateQuestion,
  useUpdateQuestionProperties,
} from '@/features/questions/hooks';
import { ATTACHMENTS_OPTIONS } from '@/features/questions/utils/attachments-options';
import { useTestPage } from '@/features/test';
import { DeleteQuestionModal } from '@/features/test/components/test-editor-question-preview/ui/delete-question-modal';
import {
  DisabledCopyContainer,
  PreviewHeader,
} from '@/features/test/components/ui';
import { CUSTOM_QUESTION_TYPE_OPTIONS } from '@/features/test/utils/question-type-options';
import { useHistory } from '@/hooks/router';

import { QuestionAnswers } from './question-answers';
import { QuestionDescription } from './question-description';
import { TestEditorQuestionPreviewProps } from './test-editor-question-preview.types';
import { QuestionNotes, QuestionSettings } from './ui';

const getAttachmentFieldLabelFromValue = (value: boolean | null) => {
  switch (value) {
    case true:
      return 'mandatory';
    case false:
      return 'optional';
    default:
      return 'optional';
  }
};

const getAttachmentValueFromLabel = (label: string) => {
  switch (label) {
    case 'mandatory':
      return true;
    case 'optional':
      return false;
    default:
      return false;
  }
};

export const CustomQuestionPreview = ({
  question,
  index,
}: TestEditorQuestionPreviewProps) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { workspace, jobOpening, test } = useTestPage();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const isVideoTest = test.type === 'video';
  const isHomework = test.type === 'homework';
  const handleEditQuestion = useCallback(() => {
    history.replace(`${url}/edit${history.location.search}`);
  }, [history, url]);

  const { isDeleting } = useDeleteQuestion(
    workspace.id,
    jobOpening.id,
    test.id,
    question.id
  );
  const { updateQuestionProperty, isUpdating } = useUpdateQuestion(test.id);
  const { updateQuestionProperties, isUpdating: isUpdatingProperties } =
    useUpdateQuestionProperties(test.id);

  const isChangingQuestion = isDeleting || isUpdating || isUpdatingProperties;
  const description = React.useMemo(
    () => createEditorState(question.question),
    [question.question]
  );

  return (
    <Container>
      <PreviewHeader.Wrapper>
        <PreviewHeader.QuestionName>
          Question {index + 1}
        </PreviewHeader.QuestionName>
        <PreviewHeader.Actions>
          <DSButton
            variant="secondary"
            size="small"
            onClick={() => {
              setIsConfirmModalOpen(true);
            }}
            disabled={isChangingQuestion}
          >
            <Icon icon={regular('trash')} />
            <span>Remove</span>
          </DSButton>
          <ContextMenuWrapper>
            <DSContextMenuButton
              active={contextMenuOpen}
              onClick={() => {
                setContextMenuOpen((prev) => !prev);
              }}
            />

            <DSContextMenu
              open={contextMenuOpen}
              position={{ top: '32px', right: '0' }}
            >
              <DSContextMenu.Item
                label="Edit question"
                icon={<Icon icon={regular('pen-to-square')} />}
                onClick={() => {
                  handleEditQuestion();
                  setContextMenuOpen(false);
                }}
              />
            </DSContextMenu>
          </ContextMenuWrapper>
        </PreviewHeader.Actions>
      </PreviewHeader.Wrapper>
      <QuestionSettings>
        <DSField for="skill" label="Skill">
          <DSSelect
            items={[{ id: 'custom', label: 'Custom' }]}
            selectedItemId={'custom'}
            onChange={() => {}}
            disabled
          />
        </DSField>
        {!isVideoTest && !isHomework ? (
          <DSField for="questionType" label="Question type">
            <DSSelect
              id="questionType"
              items={CUSTOM_QUESTION_TYPE_OPTIONS}
              groupBy="group"
              onChange={(option) => {
                updateQuestionProperty({
                  question,
                  key: 'questionType',
                  value: option?.id ?? '',
                });
                if (option) {
                  amplitude?.logEvent(
                    'question creation/change question type',
                    {
                      'job opening id': jobOpening.id,
                      'test id': test.id,
                      'question type': option.label,
                    }
                  );
                  posthog?.capture(
                    'hire_admin_app:question creation/change question type',
                    {
                      'job opening id': jobOpening.id,
                      'test id': test.id,
                      'question type': option.label,
                    }
                  );
                }
              }}
              selectedItemId={question.questionType}
              disabled={true}
            />
          </DSField>
        ) : null}
        {isHomework ? (
          <AttachmentsField
            for="attachments"
            label={
              <>
                Attachments
                <Icon
                  style={{ marginLeft: '4px' }}
                  icon={regular('circle-info')}
                  color="purple-60"
                  fontSize="12px"
                  data-rh="Accepted file types: doc, docx, xls, xlsx, odt, pdf, txt, jpg, jpeg, png"
                  data-rh-position="top"
                />
              </>
            }
          >
            <DSSelect
              id="attachments"
              items={ATTACHMENTS_OPTIONS}
              selectedItemId={getAttachmentFieldLabelFromValue(
                question.requireAttachment
              )}
              onChange={(selectedItem) =>
                updateQuestionProperties(question.id, {
                  requireAttachment: getAttachmentValueFromLabel(
                    selectedItem?.id ?? 'optional'
                  ),
                })
              }
              disabled={isChangingQuestion}
            />
          </AttachmentsField>
        ) : null}
        <DSField for="points" label="Points">
          <DSInput
            id="points"
            type="number"
            min={1}
            value={question.points}
            onChange={(event) => {
              if (isNaN(+event.target.value)) return;
              updateQuestionProperties(question.id, {
                points: +event.target.value,
              });
              amplitude?.logEvent('question creation/change points', {
                'job opening id': jobOpening.id,
                'test id': test.id,
                points: +event.target.value,
              });
              posthog?.capture(
                'hire_admin_app:question creation/change points',
                {
                  'job opening id': jobOpening.id,
                  'test id': test.id,
                  points: +event.target.value,
                }
              );
            }}
            disabled={isChangingQuestion}
          />
        </DSField>
      </QuestionSettings>
      <QuestionDescription>
        <Markdown>{getEditorMarkdown(description)}</Markdown>
      </QuestionDescription>
      <QuestionAnswers
        answer={question.answer}
        options={question.options}
        type={question.questionType}
      />
      <QuestionNotes notes={question.notes} key={question.id} />
      <DeleteQuestionModal
        open={isConfirmModalOpen}
        onClose={() => {
          setIsConfirmModalOpen(false);
        }}
        questionId={question.id}
      />
    </Container>
  );
};

const Container = styled(DisabledCopyContainer)`
  grid-template-rows: min-content min-content max-content 1fr max-content;
`;

const ContextMenuWrapper = styled.div`
  position: relative;
`;

const AttachmentsField = styled(DSField)`
  grid-column: -3 / span 1;
  min-width: 130px;
`;

import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import * as yup from 'yup';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import {
  ICancelModalStepProps,
  useDeleteSubscriptionMutation,
  usePostFeedbackChurnSurvey,
} from '@/features/billing';
import {
  FormikCheckboxField,
  FormikInputField,
  FormikTextAreaField,
} from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { trackSwitchPlan } from '@/googleTagManager';
import { useWorkspaceId } from '@/hooks/router';

import {
  CancelModalContentContainer,
  CancelModalFooterButtons,
  CancelModalHeader,
} from '.';

const FEATURES_REASON = 'We are missing certain features';

const churnSurveyReasons = [
  {
    label: 'We are not satisfied with the product performance',
    value: 'We are not satisfied with the product performance',
  },
  {
    label: 'Our company is moving to another applicant tracking tool',
    value: 'Our company is moving to another applicant tracking tool',
  },
  {
    label: 'Our company is moving to another skills testing tool',
    value: 'Our company is moving to another skills testing tool',
  },
  {
    label: 'Toggl Hire is too expensive',
    value: 'Toggl Hire is too expensive',
  },
  {
    label: 'We will not be hiring in the foreseeable future',
    value: 'We will not be hiring in the foreseeable future',
  },
  {
    label: 'We are missing certain features (please specify)',
    value: FEATURES_REASON,
  },
];

const schema = yup.object().shape({
  reasons: yup
    .array()
    .required('Select at least one reason')
    .min(1, 'Select at least one reason'),
  feedback: yup
    .string()
    .required('Input required')
    .max(500, 'Maximum 500 characters'),
  details: yup.string().when('reasons', {
    is: (reasons: string[]) => reasons.includes(FEATURES_REASON),
    then: (schema) =>
      schema.required('Input required').max(200, 'Maximum 200 characters'),
    otherwise: (schema) => schema.notRequired(),
  }),
});

type TFormValues = {
  reasons: string[];
  feedback: string;
  details: string;
};

export const FinalCancelStepModal = ({
  open,
  setCurrentStep,
}: ICancelModalStepProps) => {
  const { addNotification } = useNotifications();
  const workspaceId = useWorkspaceId();
  const postFeedbackChurnSurvey = usePostFeedbackChurnSurvey();
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();

  const deleteSubscriptionMutation = useDeleteSubscriptionMutation({
    onSuccess: () => {
      setCurrentStep(undefined);
      trackSwitchPlan('2024-09-free-ats');
      amplitude?.logEvent(`billing/subscription cancelled`, {
        'workspace id': workspaceId,
      });
      posthog?.capture(`hire_admin_app:billing/subscription cancelled`, {
        'workspace id': workspaceId,
      });
      addNotification({ type: 'saved' });
    },
  });

  const handleSubmit = useCallback(
    async (values: TFormValues) => {
      // Update reasons to include details for missing features
      const updatedReasons = values.reasons.map((reason) => {
        if (reason === FEATURES_REASON && values.details) {
          return `${reason}: ${values.details}`;
        }
        return reason;
      });

      deleteSubscriptionMutation.mutate();

      postFeedbackChurnSurvey.mutate({
        workspaceId: workspaceId,
        reasons: updatedReasons,
        feedback: values.feedback,
      });
    },
    [deleteSubscriptionMutation, postFeedbackChurnSurvey, workspaceId]
  );

  return (
    <DSModal
      fluid
      open={open}
      onClose={() => setCurrentStep(undefined)}
      contentStyle={{ width: '640px' }}
    >
      <CancelModalHeader>
        Cancel subscription
        <DSModal.CloseButton
          small
          fixed
          onClick={() => setCurrentStep(undefined)}
        />
      </CancelModalHeader>
      <DSModal.Separator />
      <CancelModalContentContainer>
        <Formik<TFormValues>
          initialValues={{
            reasons: [],
            feedback: '',
            details: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnMount
        >
          {(form) => (
            <Form>
              <FeedbackSection>
                <h2>
                  Why are you cancelling your subscription? (Please select all
                  that apply)*
                </h2>
                <CancelReasons>
                  {churnSurveyReasons.map(({ label, value }, indx) => (
                    <React.Fragment key={value}>
                      <FormikCheckboxField
                        name="reasons"
                        label={label}
                        value={value}
                        hideErrorMsg={churnSurveyReasons.length - 1 !== indx}
                        errorAbsolute
                      />
                      {value === FEATURES_REASON &&
                        form.values.reasons.includes(value) && (
                          <CancelReasonInput
                            name="details"
                            placeholder="We are missing..."
                            errorAbsolute
                            maxLength={200}
                            autoFocus
                            onFocus={() => {
                              // Prevent validation on focus
                              form.setFieldTouched('reasons', false);
                            }}
                          />
                        )}
                    </React.Fragment>
                  ))}
                </CancelReasons>
              </FeedbackSection>

              <FeedbackSection gap="12px">
                <h2>
                  Do you have any other feedback to share with us? We appreciate
                  your time to help us improve Toggl Hire.*
                </h2>

                <FormikTextAreaField
                  name="feedback"
                  placeholder="Share your thoughts or suggestions here..."
                  inputHeight="80px"
                  errorAbsolute
                  maxLength={500}
                  showLengthCounter
                />
              </FeedbackSection>

              <DSModal.Footer>
                <CancelModalFooterButtons>
                  <DSButton
                    variant="secondary"
                    type="button"
                    onClick={() => setCurrentStep(undefined)}
                  >
                    Stay on current plan
                  </DSButton>
                  <DSButton
                    variant="primary-purple"
                    type="submit"
                    disabled={!form.isValid || form.isSubmitting}
                    data-rh={
                      !form.isValid
                        ? 'Please share your feedback above to proceed'
                        : null
                    }
                    data-rh-at="top"
                  >
                    Cancel subscription
                  </DSButton>
                </CancelModalFooterButtons>
              </DSModal.Footer>
            </Form>
          )}
        </Formik>
      </CancelModalContentContainer>
    </DSModal>
  );
};

const FeedbackSection = styled.section<{ gap?: string }>`
  display: grid;
  gap: ${({ gap }) => gap || '16px'};

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.typography.colorPrimary};
    margin: 0;
  }
`;

const CancelReasons = styled.div`
  display: grid;
  gap: 12px;
`;

const CancelReasonInput = styled(FormikInputField)`
  margin-left: 24px;
`;

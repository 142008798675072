import { useEffect, useMemo } from 'react';
import { usePostHog } from 'posthog-js/react';

import { userHasConsentedToStatistics } from '@/features/gdpr/utils/permissions';
import { useMeQuery } from '@/features/me';
import { useWorkspaceUsersQuery } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';
import { isDemoEnv } from '@/utils/env';

export function usePosthogForWorkspace() {
  const workspaceId = useWorkspaceId();
  const meQuery = useMeQuery();
  const workspaceUsersQuery = useWorkspaceUsersQuery();
  const posthog = usePostHog();

  const workspaceUser = useMemo(() => {
    if (!meQuery.isSuccess || !workspaceUsersQuery.isSuccess) {
      return null;
    }

    return workspaceUsersQuery.data.find((user) => user.id === meQuery.data.id);
  }, [
    meQuery.isSuccess,
    meQuery.data?.id,
    workspaceUsersQuery.isSuccess,
    workspaceUsersQuery.data,
  ]);

  const workspace = useMemo(() => {
    if (!meQuery.isSuccess) {
      return null;
    }

    return meQuery.data.workspaces?.find(
      (workspace) => workspace.id === workspaceId
    );
  }, [meQuery.isSuccess, meQuery.data, workspaceId]);

  useEffect(() => {
    if (workspaceUser != null && userHasConsentedToStatistics()) {
      posthog?.identify(workspaceUser.id, {
        email: workspaceUser.email,
        role: workspaceUser.role,
        support: workspaceUser.id === '0',
        demo: isDemoEnv(),
        workspace_id: workspace?.id || undefined,
        subscription_plan: workspace?.subscriptionPlan || undefined,
      });
      if (workspace?.id) {
        posthog?.group('workspace', workspace.id);
      }
    }
  }, [workspace, workspaceUser, posthog]);
}

import React from 'react';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import { Redirect, Route as ReactRouterRoute, Switch } from 'react-router-dom';
import { useMedia } from 'react-use';

import { AdminPage } from '@/features/admin-app';
import { useAmplitudeForWorkspace } from '@/features/amplitude';
import { SubscribeModalContextProvider } from '@/features/billing';
import { MoreOnDesktopModal } from '@/features/common';
import { UpsellModal } from '@/features/common/components/upsell-modal/ui';
import { UpsellModalContextProvider } from '@/features/common/components/upsell-modal/upsell-modal-context';
import { EmailModal, EmailModalContextProvider } from '@/features/email';
import { useMeQuery } from '@/features/me';
import { useOnboardingWelcome } from '@/features/onboarding';
import { useWorkspacePermission } from '@/features/permissions';
import { usePosthogForWorkspace } from '@/features/posthog';
import posthog from '@/features/posthog/utils/posthog-client';
import { PreviewFeatureModal } from '@/features/preview-feature/components/preview-feature-modal';
import { PreviewFeatureModalContextProvider } from '@/features/preview-feature/components/preview-feature-modal-context';
import ShareModal from '@/features/share/components/share-modal';
import { ShareModalContextProvider } from '@/features/share/components/share-modal-context';
import { WorkspaceSplit } from '@/features/splits';
import {
  AddTestToPipelineModal,
  AddTestToPipelineModalContextProvider,
} from '@/features/test-library';
import { getSharedAuthEnabled } from '@/features/toggl-accounts';
import {
  GenericPricingUpsellModal,
  GenericPricingUpsellModalContextProvider,
} from '@/features/upsell/components/generic-pricing-upsell-modal';
import { useWorkspace, useWorkspaceStatsQuery } from '@/features/workspace';
import { useLocation } from '@/hooks/router';
import AccountSettingsPage from '@/pages/account-settings-page';
import HomePage from '@/pages/home-page';
import JobOpeningDescriptionPage from '@/pages/job-opening-description-page';
import InterviewPage from '@/pages/openings/interview-page';
import JobOpeningSharePage from '@/pages/openings/job-opening-share-page';
import TestPage from '@/pages/openings/test-page';
import ProfileSettingsPage from '@/pages/profile-settings-page';
import { TestLibraryPage } from '@/pages/test-library-page';
import { RouterMatch } from '@/types/router';
import { getActiveDashboardTab } from '@/utils/dashboard';

import * as pages from './pages';

interface WorkspaceAppProps {
  match: RouterMatch;
}

const Route = Sentry.withSentryRouting(ReactRouterRoute);

export const WorkspaceApp = ({ match }: WorkspaceAppProps) => {
  const location = useLocation();
  const isMobile = useMedia('(max-width: 768px)');
  const canReadJobOpenings = useWorkspacePermission()('job openings', 'read');
  const sharedAuthEnabled = getSharedAuthEnabled();
  const workspace = useWorkspace();
  const workspaceStatsQuery = useWorkspaceStatsQuery({
    workspaceId: match.params.workspace,
  });
  const meQuery = useMeQuery();

  useAmplitudeForWorkspace();
  usePosthogForWorkspace();

  const onboardingDone = useOnboardingWelcome();

  if (
    !workspace ||
    workspaceStatsQuery.isLoading ||
    meQuery.isLoading ||
    !onboardingDone
  ) {
    return null;
  }
  return (
    <PostHogProvider client={posthog}>
      <SubscribeModalContextProvider>
        <ShareModalContextProvider>
          <GenericPricingUpsellModalContextProvider>
            <EmailModalContextProvider>
              <UpsellModalContextProvider>
                <PreviewFeatureModalContextProvider>
                  <AddTestToPipelineModalContextProvider>
                    <WorkspaceSplit>
                      <div className="workspace-app">
                        <Switch>
                          {!!workspace.transferOwnershipRequestId ? (
                            <Redirect
                              from={match.url}
                              to={`${match.url}/settings/team`}
                              exact
                            />
                          ) : canReadJobOpenings ? (
                            <Redirect
                              from={match.url}
                              to={{
                                pathname: `${match.url}/${
                                  isMobile
                                    ? 'home'
                                    : !workspaceStatsQuery.data
                                          ?.totalJobOpenings
                                      ? 'openings'
                                      : getActiveDashboardTab()
                                }`,
                                search: location.search,
                              }}
                              exact
                            />
                          ) : (
                            <Redirect
                              from={match.url}
                              to={{
                                pathname: `${match.url}/pipeline`,
                                search: location.search,
                              }}
                              exact
                            />
                          )}

                          <Route
                            path={`${match.path}/home`}
                            component={HomePage}
                          />
                          <Route
                            exact
                            path={`${match.path}/:page(openings|candidates)/(test-library)?`}
                            render={() => (
                              <AdminPage>
                                <pages.DashboardPage />
                              </AdminPage>
                            )}
                          />
                          <Route
                            path={`${match.url}/openings/:opening(\\d+)`}
                            render={() => {
                              return (
                                // TODO: We should probably have a wrapper for all pages under /admin so the AdminPage is always rendered there, instead of being rendered route by route
                                <AdminPage>
                                  <Switch>
                                    {/* Test Page does not use the default Job Opening Page because the header is different */}
                                    <Route
                                      path={`${match.path}/openings/:opening/test/:testId`}
                                      component={TestPage}
                                    />

                                    <Route
                                      path={`${match.path}/openings/:opening/interview/:interviewId`}
                                      component={InterviewPage}
                                    />

                                    <Route
                                      path={`${match.url}/openings/:opening(\\d+)`}
                                      render={() => {
                                        return (
                                          <pages.JobOpeningPage>
                                            <Switch>
                                              <Route
                                                path={`${match.path}/openings/:opening/job-settings`}
                                                component={
                                                  pages.JobOpeningSettingsPage
                                                }
                                              />

                                              <Route
                                                path={`${match.path}/openings/:opening/job-description`}
                                                component={
                                                  JobOpeningDescriptionPage
                                                }
                                              />

                                              <Route
                                                path={`${match.path}/openings/:opening/application-form`}
                                                component={
                                                  pages.JobOpeningApplicationFormPage
                                                }
                                              />

                                              <Route
                                                path={`${match.path}/openings/:opening/share`}
                                                component={JobOpeningSharePage}
                                              />

                                              <Route
                                                path={`${match.path}/openings/:opening/analytics`}
                                                component={
                                                  pages.JobOpeningAnalyticsPage
                                                }
                                              />

                                              <Route
                                                path={`${match.path}/openings/:opening/pipeline/stage/recommended/candidate/:candidateId`}
                                                component={
                                                  pages.PipelineRecommendedSingleStagePage
                                                }
                                              />

                                              <Route
                                                path={`${match.path}/openings/:opening/pipeline/stage/:stageId/candidate/:candidateId/:tab(application|results|email|notes|history|attachments)/:tabItemId?/:noteId?`}
                                                component={
                                                  pages.PipelineSingleStagePage
                                                }
                                              />

                                              <Route
                                                path={`${match.path}/openings/:opening/pipeline`}
                                                component={pages.PipelinePage}
                                              />

                                              <Route
                                                path={`${match.path}/openings/:opening/satisfaction`}
                                                component={
                                                  pages.CandidateSatisfactionPage
                                                }
                                              />

                                              <Redirect
                                                from={`${match.path}/openings/:opening(\\d+)`}
                                                to={{
                                                  pathname: `${match.url}/openings/:opening/pipeline`,
                                                  search: location.search,
                                                }}
                                                exact
                                              />
                                            </Switch>
                                          </pages.JobOpeningPage>
                                        );
                                      }}
                                    />
                                  </Switch>
                                </AdminPage>
                              );
                            }}
                          />
                          <Route
                            path={`${match.url}/candidates`}
                            render={() => <Redirect to={`${match.url}`} />}
                          />

                          {sharedAuthEnabled && (
                            <Route
                              path={`${match.url}/account`}
                              render={() => (
                                <AdminPage>
                                  <AccountSettingsPage />
                                </AdminPage>
                              )}
                            />
                          )}
                          <Route
                            path={`${match.path}/settings/profile`}
                            render={() => (
                              <AdminPage>
                                <ProfileSettingsPage />
                              </AdminPage>
                            )}
                          />
                          <Route
                            path={`${match.path}/settings/integrations`}
                            render={() => (
                              <AdminPage>
                                <pages.WorkspaceSettingsIntegration />
                              </AdminPage>
                            )}
                          />
                          <Route
                            path={`${match.path}/settings/team`}
                            render={() => (
                              <AdminPage>
                                <pages.TeamSettingsPage />
                              </AdminPage>
                            )}
                          />
                          <Route
                            path={`${match.path}/settings/email-templates`}
                            render={() => (
                              <AdminPage>
                                <pages.SettingsEmailTemplatePage />
                              </AdminPage>
                            )}
                          />

                          <Route
                            path={`${match.path}/settings/billing`}
                            render={() => (
                              <AdminPage>
                                <pages.BillingPage />
                              </AdminPage>
                            )}
                          />
                          <Route
                            path={`${match.path}/inbox`}
                            render={() => (
                              <AdminPage>
                                <pages.InboxPage />
                              </AdminPage>
                            )}
                          />
                          <Route
                            path={`${match.path}/test-library/:jobOpeningId/:categoryId/:templateId`}
                            render={() => (
                              <AdminPage>
                                <pages.TestLibraryDetailsPage />
                              </AdminPage>
                            )}
                          />
                          <Route
                            path={`${match.path}/test-library/:jobOpeningId/:categoryId/`}
                            render={() => {
                              return (
                                <AdminPage>
                                  <TestLibraryPage />
                                </AdminPage>
                              );
                            }}
                          />

                          <Route
                            path={`${match.path}/test-library/:templateId`}
                            render={() => (
                              <AdminPage>
                                <pages.TestLibraryDetailsPage />
                              </AdminPage>
                            )}
                          />
                          <Route
                            path={`${match.path}/test-library`}
                            render={() => {
                              return (
                                <AdminPage>
                                  <TestLibraryPage />
                                </AdminPage>
                              );
                            }}
                          />
                        </Switch>
                      </div>
                      <EmailModal />
                      <ShareModal />
                      <UpsellModal />
                      <PreviewFeatureModal />
                      <MoreOnDesktopModal />
                      <AddTestToPipelineModal />
                      <GenericPricingUpsellModal />
                    </WorkspaceSplit>
                  </AddTestToPipelineModalContextProvider>
                </PreviewFeatureModalContextProvider>
              </UpsellModalContextProvider>
            </EmailModalContextProvider>
          </GenericPricingUpsellModalContextProvider>
        </ShareModalContextProvider>
      </SubscribeModalContextProvider>
    </PostHogProvider>
  );
};
